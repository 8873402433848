import React, { useState } from "react";

const TreeNode = ({ label, isChecked, onChange, check }) => {
  return (
    <div className="px-2 py-1 flex space-x-0.5">
      {check && (
        <input type="checkbox" checked={isChecked} onChange={onChange} />
      )}
      <span className="text-base font-semibold ">{label}</span>
    </div>
  );
};

export default TreeNode;
