import React, { useEffect, useState } from "react";
import "./style.css";
import { FishboneChart } from "react-fishbone-chart";
import CascadingSelectFishBone from "./CascadingSelectFishBone";
import TreeView from "./components/TreeView/TreeView";
import {
  fishboneData,
  fishboneDataAsChildren,
  fishboneDataUndesirable
} from "./data";
import { AiOutlineClear } from "react-icons/ai";
import { VscGitPullRequestCreate } from "react-icons/vsc";
const App = () => {
  const [showSidebar, setShowSidebar] = useState(true);
  const [show, setShow] = useState(false);
  const [reload, setReload] = useState(false);
  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };
  const [data, setData] = useState({
    name: "Yöntem",
    children: [
      {
        name: "Yönetim",
        children: [{ name: "" }]
      },
      {
        name: "Çalışma Ortamı",
        children: [{ name: "" }]
      },
      {
        name: "Ekipman",
        children: [{ name: "" }]
      },
      {
        name: "Çalışan",
        children: [{ name: "" }]
      },
      {
        name: "Yöntem",
        children: [{ name: "" }]
      }
    ]
  });
  const [selectedFishbone, setSelectedFishbone] = useState({
    undesirableEvent: "",
    method: [],
    worker: [],
    equipment: [],
    administration: [],
    workingEnvironment: []
  });

  const [selectedNodes, setSelectedNodes] = useState([]);

  const handleCreatingData = () => {
    setReload(false);
    const data = {
      name: selectedFishbone.undesirableEvent, // Take the first selected undesirableEvent
      children: [
        {
          name: "Yönetim",
          children: selectedFishbone.administration.map((item) => ({
            name: item
          }))
        },
        {
          name: "Çalışma Ortamı",
          children: selectedFishbone.workingEnvironment.map((item) => ({
            name: item
          }))
        },
        {
          name: "Ekipman",
          children: selectedFishbone.equipment.map((item) => ({ name: item }))
        },
        {
          name: "Çalışan",
          children: selectedFishbone.worker.map((item) => ({ name: item }))
        },
        {
          name: "Yöntem",
          children: selectedFishbone.method.map((item) => ({ name: item }))
        }
      ]
    };

    setTimeout(() => {
      setData(data);
      setReload(true);
      setShow(true);
    }, 500);
  };

  useEffect(() => {
    setData({
      name: "Yöntem",
      children: [
        {
          name: "Yönetim",
          children: [{ name: selectedFishbone.administration }]
        },
        {
          name: "Çalışma Ortamı",
          children: [{ name: selectedFishbone.workingEnvironment }]
        },
        {
          name: "Ekipman",
          children: [{ name: selectedFishbone.equipment }]
        },
        {
          name: "Çalışan",
          children: [{ name: selectedFishbone.worker }]
        },
        {
          name: "Yöntem",
          children: [{ name: selectedFishbone.method }]
        }
      ]
    });
  }, [selectedFishbone]);

  const clearHandler = () => {
    setSelectedNodes([]);
    setSelectedFishbone({
      undesirableEvent: "",
      method: [],
      worker: [],
      equipment: [],
      administration: [],
      workingEnvironment: []
    });
  };

  return (
    <div className="w-full">
      <button
        onClick={toggleSidebar}
        className={`${
          showSidebar ? "hidden" : "block"
        } px-2 py-1 rounded-md border border-gray-400 m-2 hover:bg-slate-500 hover:text-white font-semibold`}
      >
        Diyagram Oluştur
      </button>
      {showSidebar && (
        <div className="fixed flex flex-col justify-between top-0 left-0 z-10 min-w-[320px] max-w-[700px] h-full transition-all  duration-500 transform bg-white px-6 py-3">
          <div className="flex flex-col space-y-2 flex-grow overflow-x-hidden">
            <div className="relative">
              <h1 className="text-xl font-semibold">
                Balık Kılçığı Diyagramı Akışı
              </h1>
              <button
                className="absolute font-bold right-0 top-0 z-10"
                onClick={toggleSidebar}
              >
                X
              </button>
            </div>
            <div className="flex flex-col space-y-1 px-2 py-1 items-center border-t border-gray-400 pt-1">
              <label htmlFor="" className="text-lg font-medium w-full">
                İstenmeyen Olay
              </label>
              <select
                className="px-2 py-1 flex-grow border rounded-md text-base w-full"
                name="mistakes"
                onChange={(e) =>
                  setSelectedFishbone({
                    ...selectedFishbone,
                    undesirableEvent: e.target.value
                  })
                }
              >
                <option value="" disabled selected>
                  Olay seçiniz
                </option>
                {fishboneDataUndesirable[0].children[0].children?.map(
                  (item) => (
                    <option key={item.key} value={item.name}>
                      {item.name}
                    </option>
                  )
                )}
              </select>
            </div>
            <TreeView
              selectedFishbone={selectedFishbone}
              setSelectedFishbone={setSelectedFishbone}
              selectedNodes={selectedNodes}
              setSelectedNodes={setSelectedNodes}
            />
          </div>
          <div className="border-t border-gray-400 flex flex-col space-y-2 py-2 justify-center">
            <div
              onClick={handleCreatingData}
              className="cursor-pointer flex space-x-2 justify-center items-center text-gray-700 hover:bg-gray-700 hover:text-white px-2 py-1 rounded-md"
            >
              <VscGitPullRequestCreate size={25} />

              <h1 className="text-base font-semibold">Diyagram Oluştur</h1>
            </div>
            <div
              className="cursor-pointer flex space-x-2 justify-center items-center text-gray-700 hover:bg-gray-700 hover:text-white px-2 py-1 rounded-md"
              onClick={clearHandler}
            >
              <AiOutlineClear size={25} />
              <h1 className="text-base font-semibold">Seçimleri Temizle</h1>
            </div>
          </div>
        </div>
      )}
      <div
        className="mb-5 flex items-center justify-center"
        style={{
          marginLeft: showSidebar ? "320px" : "10px",
          width: "100%",
          height: "95vh"
        }}
      >
        {reload && show && <FishboneChart data={data} />}
      </div>
    </div>
  );
};
export default App;
