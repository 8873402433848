export const fishboneData = {
  undesirableEvent: [
    "Düşme",
    "Fırlayan, düşen nesne çarpması",
    "Bina, yapı kısmı çökmesi",
    "Elektriğe maruz kalma",
    "Göçmeler (kazı sırasında veya sonrasında)",
    "Ekipman kaynaklı kazalar",
    "Duman, ateş ve aleve maruz kalma",
    "Nesnelerin içinde veya arasında sıkışma, ezilme",
    "Şantiye içi trafik kazaları",
    "Makine parçası arasına sıkışma,/uzuv kaptırma"
  ],
  method: [
    " Planlama aşamalarındaki eksiklikler",
    "İşletme planının dikkate alınmaması",
    " Yapım sürelerinin kısa tutulması",
    "Çeşitli firmaların beraber çalışmalarının düzenlenmemesi",
    "Konstruktif eksiklikler",
    "Kötü yapı malzemesi kullanılması",
    "İşin niteliğine uygun olmayan malzeme kullanılması",
    "Malzeme kusurlarının bulunması",
    "Ön hazırlık çalışmalarının, şantiye kuruluşunun hatalı veya eksik olması",
    " Gerekli izinlerin alınmadan iş yapılması",
    "Yapı destek sistemlerinin kurulmaması"
  ],
  worker: [
    "Mesleki eğitimin yetersiz olması",
    " Tecrübe eksikliği bulunması",
    "Sorumluluk bilincinin eksik olması",
    "Keyfi davranışlarda bulunulması",
    "Düşüncesizlik, hafiflik, umursamazlık",
    "Dikkatsizlik",
    "Yorgunluk",
    "İşçilik hataları yapılması",
    " Kişisel koruyucu malzemenin kullanılmaması",
    "Çalışanların gerektiğinde yardımcı personel talep etmemesi",
    "Sağlık ve güvenlik eğitimi olmaması",
    "Sağlık sorunu olan çalışan",
    "Görev veya yetki dışında iş yapılması"
  ],
  equipment: [
    "Gerekli kişisel koruyucu donanımların hiç bulunmaması",
    "Gerekli kişisel koruyucu donanımların yetersiz olması",
    "Ekipmanların yapılan işe uygun olmaması",
    "Periyodik kontrolü gerçekleştirilmeyen ekipman"
  ],
  administration: [
    "Koruma önlemlerinin eksik olması",
    "İşle ilgili talimatların hatalı olması",
    "Makine araç ve tertibatlarının kontrolünün yeterli olmaması",
    "Çalışanların gerektiği gibi denetlenmemesi",
    "İşin fenni yeterliliği bulunan kişilerin teknik gözetimi altında yapılmaması",
    "Uygulamadaki düzenli kontrollerin yetersiz olması",
    "İşlerin konusunda uzman olmayan firmalara verilmesi",
    "Yeterli sayıda iş sağlığı ve güvenliği profesyonelleri istihdam edilmemesi",
    "İzinsiz yapı alanına giriş",
    "Risk değerlendirmesi ve acil durum eylem planı yapılmaması"
  ],
  workingEnvironment: [
    "Uyarı levhalarının bulunmaması",
    "Döşeme kenarlarında korkuluk olmaması",
    "Korkuluk bulunmayan merdivenler, merdiven ve asansör boşlukları",
    "Kurallara uygun şekilde istif edilmemiş malzemeler",
    "Çevresi perde ile çevrilmemiş yapı alanı",
    "Kazı işlemi sırasında gerekli şev payının oluşturulmaması",
    "Korkuluğu bulunmayan iskeleler",
    "Binaya tespit edilmemiş iskeleler ve yetersiz çapraz bağlantılar",
    "Mevzuata uygun olmayan gırgır vinç ve asansör",
    "Asgari şartlara uygun olmadan kullanılan makineler",
    "Uygun olmayan malzeme istiflenmesi"
  ]
};

export const fishboneDataAsChildren = [
  {
    key: "1",
    name: "Nedenler",
    check: false,
    children: [
      {
        key: "13",
        name: "Yöntem",
        check: false,
        children: [
          {
            key: "131",
            name: "Planlama aşamalarındaki eksiklikler",
            check: true
          },
          {
            key: "132",
            name: "İşletme planının dikkate alınmaması",
            check: true
          },
          {
            key: "133",
            name: "Yapım sürelerinin kısa tutulması",
            check: true
          },
          {
            key: "134",
            name: "Çeşitli firmaların beraber çalışmalarının düzenlenmemesi",
            check: true
          },
          {
            key: "135",
            name: "Konstruktif eksiklikler",
            check: true
          },
          {
            key: "136",
            name: "Kötü yapı malzemesi kullanılması",
            check: true
          },
          {
            key: "137",
            name: "İşin niteliğine uygun olmayan malzeme kullanılması",
            check: true
          },
          {
            key: "138",
            name: "Malzeme kusurlarının bulunması",
            check: true
          },
          {
            key: "139",
            name: "Ön hazırlık çalışmalarının, şantiye kuruluşunun hatalı veya eksik olması",
            check: true
          },
          {
            key: "1310",
            name: "Gerekli izinlerin alınmadan iş yapılması",
            check: true
          },
          {
            key: "1311",
            name: "Yapı destek sistemlerinin kurulmaması",
            check: true
          }
        ]
      },
      {
        key: "14",
        name: "Çalışan",
        check: false,
        children: [
          {
            key: "141",
            name: "Mesleki eğitimin yetersiz olması",
            check: true
          },
          {
            key: "142",
            name: "Tecrübe eksikliği bulunması",
            check: true
          },
          {
            key: "143",
            name: "Sorumluluk bilincinin eksik olması",
            check: true
          },
          {
            key: "144",
            name: "Keyfi davranışlarda bulunulması",
            check: true
          },
          {
            key: "145",
            name: "Düşüncesizlik, hafiflik, umursamazlık",
            check: true
          },
          {
            key: "146",
            name: "Dikkatsizlik",
            check: true
          },
          {
            key: "147",
            name: "Yorgunluk",
            check: true
          },
          {
            key: "148",
            name: "İşçilik hataları yapılması",
            check: true
          },
          {
            key: "149",
            name: "Kişisel koruyucu malzemenin kullanılmaması",
            check: true
          },
          {
            key: "1410",
            name: "Çalışanların gerektiğinde yardımcı personel talep etmemesi",
            check: true
          },
          {
            key: "1411",
            name: "Sağlık ve güvenlik eğitimi olmaması",
            check: true
          },
          {
            key: "1412",
            name: "Sağlık sorunu olan çalışan",
            check: true
          },
          {
            key: "1413",
            name: "Görev veya yetki dışında iş yapılması",
            check: true
          }
        ]
      },
      {
        key: "15",
        name: "Ekipman",
        check: false,
        children: [
          {
            key: "151",
            name: "Gerekli kişisel koruyucu donanımların hiç bulunmaması",
            check: true
          },
          {
            key: "152",
            name: "Gerekli kişisel koruyucu donanımların yetersiz olması",
            check: true
          },
          {
            key: "153",
            name: "Ekipmanların yapılan işe uygun olmaması",
            check: true
          },
          {
            key: "154",
            name: "Periyodik kontrolü gerçekleştirilmeyen ekipman",
            check: true
          }
        ]
      },
      {
        key: "16",
        name: "Yönetim",
        check: false,
        children: [
          {
            key: "161",
            name: "Koruma önlemlerinin eksik olması",
            check: true
          },
          {
            key: "162",
            name: "İşle ilgili talimatların hatalı olması",
            check: true
          },
          {
            key: "163",
            name: "Makine araç ve tertibatlarının kontrolünün yeterli olmaması",
            check: true
          },
          {
            key: "164",
            name: "Çalışanların gerektiği gibi denetlenmemesi",
            check: true
          },
          {
            key: "165",
            name: "İşin fenni yeterliliği bulunan kişilerin teknik gözetimi altında yapılmaması",
            check: true
          },
          {
            key: "166",
            name: "Uygulamadaki düzenli kontrollerin yetersiz olması",
            check: true
          },
          {
            key: "167",
            name: "İşlerin konusunda uzman olmayan firmalara verilmesi",
            check: true
          },
          {
            key: "168",
            name: "Yeterli sayıda iş sağlığı ve güvenliği profesyonelleri istihdam edilmemesi",
            check: true
          },
          {
            key: "169",
            name: "İzinsiz yapı alanına giriş",
            check: true
          },
          {
            key: "1610",
            name: "Risk değerlendirmesi ve acil durum eylem planı yapılmaması",
            check: true
          }
        ]
      },
      {
        key: "17",
        name: "Çalışma Ortamı",
        check: false,
        children: [
          {
            key: "171",
            name: "Uyarı levhalarının bulunmaması",
            check: true
          },
          {
            key: "172",
            name: "Döşeme kenarlarında korkuluk olmaması",
            check: true
          },
          {
            key: "173",
            name: "Korkuluk bulunmayan merdivenler, merdiven ve asansör boşlukları",
            check: true
          },
          {
            key: "174",
            name: "Kurallara uygun şekilde istif edilmemiş malzemeler",
            check: true
          },
          {
            key: "175",
            name: "Çevresi perde ile çevrilmemiş yapı alanı",
            check: true
          },
          {
            key: "176",
            name: "Kazı işlemi sırasında gerekli şev payının oluşturulmaması",
            check: true
          },
          {
            key: "177",
            name: "Korkuluğu bulunmayan iskeleler",
            check: true
          },
          {
            key: "178",
            name: "Binaya tespit edilmemiş iskeleler ve yetersiz çapraz bağlantılar",
            check: true
          },
          {
            key: "179",
            name: "Mevzuata uygun olmayan gırgır vinç ve asansör",
            check: true
          },
          {
            key: "1710",
            name: "Asgari şartlara uygun olmadan kullanılan makineler",
            check: true
          },
          {
            key: "1711",
            name: "Uygun olmayan malzeme istiflenmesi",
            check: true
          }
        ]
      }
    ]
  }
];
export const fishboneDataUndesirable = [
  {
    key: "1",
    name: "Nedenler",
    check: false,
    children: [
      {
        key: "12",
        name: "İstenmeyen Olay",
        check: false,
        children: [
          {
            key: "121",
            name: "Düşme",
            check: true
          },
          {
            key: "122",
            name: "Fırlayan, düşen nesne çarpması",
            check: true
          },
          {
            key: "123",
            name: "Bina, yapı kısmı çökmesi",
            check: true
          },
          {
            key: "124",
            name: "Elektriğe maruz kalma",
            check: true
          },
          {
            key: "125",
            name: "Göçmeler (kazı sırasında veya sonrasında)",
            check: true
          },
          {
            key: "126",
            name: "Ekipman kaynaklı kazalar",
            check: true
          },
          {
            key: "127",
            name: "Duman, ateş ve aleve maruz kalma",
            check: true
          },
          {
            key: "128",
            name: "Nesnelerin içinde veya arasında sıkışma, ezilme",
            check: true
          },
          {
            key: "129",
            name: "Şantiye içi trafik kazaları",
            check: true
          },
          {
            key: "1210",
            name: "Makine parçası arasına sıkışma,/uzuv kaptırma",
            check: true
          }
        ]
      }
    ]
  }
];
