import React, { useState, useEffect } from "react";
import TreeNode from "./TreeNode";
import { fishboneDataAsChildren as data } from "../../data";

const TreeView = ({ selectedNodes, setSelectedNodes, setSelectedFishbone }) => {
  const [checkedItems, setCheckedItems] = useState({});
  const [expandedItems, setExpandedItems] = useState({});
  const [isUndesirableEventSelected, setIsUndesirableEventSelected] =
    useState(false);
  const [alreadySelected, setAlreadySelected] = useState(false);

  useEffect(() => {
    const selectedKeys = selectedNodes.map((node) => node.key);
    const updatedCheckedItems = {};

    selectedKeys.forEach((key) => {
      updatedCheckedItems[key] = true;
    });

    setCheckedItems(updatedCheckedItems);
  }, [selectedNodes]);

  // const handleCheckboxChange = (key) => {
  //   setCheckedItems((prevState) => ({
  //     ...prevState,
  //     [key]: !prevState[key]
  //   }));

  //   if (!checkedItems[key]) {
  //     const selectedNode = findNodeByKey(data, key);
  //     if (selectedNode) {
  //       setSelectedNodes((prevSelectedNodes) => [
  //         ...prevSelectedNodes,
  //         selectedNode
  //       ]);
  //     }
  //   } else {
  //     setSelectedNodes((prevSelectedNodes) =>
  //       prevSelectedNodes.filter((node) => node.key !== key)
  //     );
  //   }
  // };

  const handleCheckboxChange = (key) => {
    // Toggle the checkbox state
    setCheckedItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));

    // Define selectedNode by calling findNodeByKey
    const selectedNode = findNodeByKey(data, key);

    // Check if the checkbox is now unchecked
    if (!checkedItems[key]) {
      if (key.startsWith("12") && isUndesirableEventSelected) {
        setAlreadySelected(true);
        alert("Another 'undesirableEvent' is already selected.");
      }
      if (selectedNode) {
        setSelectedNodes((prevSelectedNodes) => [
          ...prevSelectedNodes,
          selectedNode
        ]);

        // Determine the category based on the key and update selectedFishbone
        if (key.startsWith("12")) {
          setIsUndesirableEventSelected(true);
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            undesirableEvent: selectedNode.name
          }));
        } else if (key.startsWith("13")) {
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            method: [...prevSelectedFishbone.method, selectedNode.name]
          }));
        } else if (key.startsWith("14")) {
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            worker: [...prevSelectedFishbone.worker, selectedNode.name]
          }));
        } else if (key.startsWith("15")) {
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            equipment: [...prevSelectedFishbone.equipment, selectedNode.name]
          }));
        } else if (key.startsWith("16")) {
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            administration: [
              ...prevSelectedFishbone.administration,
              selectedNode.name
            ]
          }));
        } else if (key.startsWith("17")) {
          setSelectedFishbone((prevSelectedFishbone) => ({
            ...prevSelectedFishbone,
            workingEnvironment: [
              ...prevSelectedFishbone.workingEnvironment,
              selectedNode.name
            ]
          }));
        }
      }
    } else {
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((node) => node.key !== key)
      );

      // Remove the node from the respective category in selectedFishbone state
      if (key.startsWith("12")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          undesirableEvent: null
        }));
      } else if (key.startsWith("13")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          method: prevSelectedFishbone.method.filter(
            (name) => name !== selectedNode.name
          )
        }));
      } else if (key.startsWith("14")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          worker: prevSelectedFishbone.worker.filter(
            (name) => name !== selectedNode.name
          )
        }));
      } else if (key.startsWith("15")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          equipment: prevSelectedFishbone.equipment.filter(
            (name) => name !== selectedNode.name
          )
        }));
      } else if (key.startsWith("16")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          administration: prevSelectedFishbone.administration.filter(
            (name) => name !== selectedNode.name
          )
        }));
      } else if (key.startsWith("17")) {
        setSelectedFishbone((prevSelectedFishbone) => ({
          ...prevSelectedFishbone,
          workingEnvironment: prevSelectedFishbone.workingEnvironment.filter(
            (name) => name !== selectedNode.name
          )
        }));
      }
    }

    if (!checkedItems[key]) {
      // Checkbox was checked, add the corresponding node to selectedNodes
      const selectedNode = findNodeByKey(data, key);
      if (selectedNode) {
        setSelectedNodes((prevSelectedNodes) => [
          ...prevSelectedNodes,
          selectedNode
        ]);
      }
    } else {
      // Checkbox was unchecked, remove the corresponding node from selectedNodes
      setSelectedNodes((prevSelectedNodes) =>
        prevSelectedNodes.filter((node) => node.key !== key)
      );
    }
  };

  const findNodeByKey = (nodes, key) => {
    for (const node of nodes) {
      if (node.key === key) {
        return node;
      }
      if (node.children) {
        const childNode = findNodeByKey(node.children, key);
        if (childNode) {
          return childNode;
        }
      }
    }
    return undefined;
  };

  const handleToggleExpand = (key) => {
    setExpandedItems((prevState) => ({
      ...prevState,
      [key]: !prevState[key]
    }));
  };

  const renderTree = (data, isChild = false) => {
    return data.map((node) => (
      <div
        key={node.key}
        className="border-t border-gray-400 pt-1 overflow-y-auto"
      >
        <div
          className="flex space-x-1"
          style={{ paddingLeft: isChild ? "20px" : "0" }}
        >
          <TreeNode
            label={node.name}
            check={node.check}
            isChecked={checkedItems[node.key] || false}
            onChange={() => handleCheckboxChange(node.key)}
          />
          {node.children && (
            <button
              onClick={() => handleToggleExpand(node.key)}
              style={{ marginLeft: "5px" }}
            >
              {expandedItems[node.key] ? "🔼" : "🔽"}
            </button>
          )}
        </div>
        {expandedItems[node.key] && node.children && (
          <div style={{ paddingLeft: "20px" }}>
            {renderTree(node.children, true)}
          </div>
        )}
      </div>
    ));
  };

  return <div className="overflow-y-auto">{renderTree(data)}</div>;
};

export default TreeView;
